<template>
  <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
      :safe-area-inset-top="true"
  />
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      title:'',
    }
  },
  methods:{
    onClickLeft() {
      this.$router.go(-1)

    }
  },
  mounted() {
    this.title = this.$route.meta.title;
  },

}
</script>

<style scoped>

</style>
